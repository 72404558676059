import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth === true ? <Component {...props} /> : <Redirect to='/Login' />
    }
  />
);

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  auth: PropTypes.bool,
  cardIdArray: PropTypes.array,
};
