import React from 'react';
import PropTypes from 'prop-types';

export default function CardNavigation(props) {
  const current = props.cardId;
  let count = props.cardCount;
  let prev = props.cardId - 1;
  let next = current + 1;

  if (prev === 0) {
    prev = count;
  }

  if (next > count) {
    next = 1;
  }

  const handleNavClick = (id) => {
    props.getCardFromNavClick(id);
  };

  return (
    <div className='card-nav'>
      <nav className='pagination is-small' role='navigation' aria-label='pagination'>
        <a
          className='pagination-previous has-background-info has-text-white box-shadow'
          onClick={() => handleNavClick(prev)}
        >
          Previous
        </a>
        <a
          className='pagination-next has-background-info has-text-white box-shadow'
          onClick={() => handleNavClick(next)}
        >
          Next
        </a>
      </nav>
    </div>
  );
}

CardNavigation.propTypes = {
  cardId: PropTypes.number,
  cardCount: PropTypes.number,
  getCardFromNavClick: PropTypes.func,
};
