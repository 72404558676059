import React, { useEffect } from 'react';
import Header from './common/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './HomePage';
import Login from './Login';
import ListCards from './ListCards';
import CardMain from './CardMain';
import CardForm from './CardForm';
import ProtectedRoute from './../utils/ProtectedRoute';
import { checkCookie, setCookie } from './../utils/cookies';
import { cardCount } from '../controllers/cardsCntl';

export default function Main() {
  let isLoggedIn = false;
  let count = 0;

  if (checkCookie('loginData')) {
    isLoggedIn = true;
  }
  const passThrough = true;

  useEffect(() => {
    async function getCount() {
      count = await cardCount();
      setCookie('card-count', count);
    }

    getCount();
  }, []);

  return (
    <>
      <Router>
        <Header />

        <div className='main-section'>
          <div style={{ clear: 'both' }}></div>
          <main>
            <Switch>
              <ProtectedRoute
                exact
                path='/'
                component={HomePage}
                auth={passThrough}
              />
              <ProtectedRoute
                exact
                path='/listcards'
                component={ListCards}
                auth={passThrough}
              />
              <ProtectedRoute
                exact
                path='/card/:id'
                component={CardMain}
                auth={passThrough}
              />
              <ProtectedRoute
                exact
                path='/cardform/:id'
                component={CardForm}
                auth={isLoggedIn}
              />
              <Route path='/login/:out' component={Login} />
            </Switch>
          </main>
        </div>
      </Router>
    </>
  );
}
