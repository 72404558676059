import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function CardBody(props) {
  const { cardId, scripture, scriptureText, hint } = props;
  const [cardHint, setCardHint] = useState('');
  const [showHint, setShowHint] = useState(1);
  const [hintLink, setHintLink] = useState('Show Hint');
  const [scriptureBody, setScriptureBody] = useState('');
  const [showScripture, setShowScripture] = useState(1);

  const ScriptureText = () => {
    return <div className='scripture-text'>{scriptureText}</div>;
  };

  const Hint = () => {
    return <div style={{ marginTop: '20px', fontSize: '1.15em' }}>{hint}</div>;
  };

  const showHintClick = (show) => {
    if (show) {
      setCardHint(<Hint />);
      setShowHint(0);
      setHintLink('Hide Hint');
    } else {
      setCardHint('');
      setHintLink('Show Hint');
    }
  };

  const handleShowScriptureClick = (show) => {
    if (show) {
      setScriptureBody(<ScriptureText />);
      setShowScripture(1);
    }
  };

  const ScriptureButton = () => {
    return (
      <div className='scripture-button'>
        <button
          className='button is-small has-background-primary-dark has-text-white'
          onClick={() => handleShowScriptureClick(showScripture)}
        >
          Show Scripture
        </button>
      </div>
    );
  };

  useEffect(() => {
    setCardHint('');
    setShowHint(1);
    setHintLink('Show Hint');
    setScriptureBody(<ScriptureButton />);
    setShowScripture(1);
  }, [cardId]);

  return (
    <div className='card-body'>
      <div style={{ textAlign: 'left' }}>{`ID: ${cardId}`}</div>
      <div>
        <span className='scripture'>{scripture}</span>
      </div>
      {scriptureBody}
      <div style={{ marginTop: '15px' }}>
        <button
          className='button is-small has-background-primary-dark has-text-white'
          onClick={() => showHintClick(showHint)}
        >
          {hintLink}
        </button>
      </div>
      {cardHint}
    </div>
  );
}

CardBody.propTypes = {
  cardId: PropTypes.number,
  scripture: PropTypes.string,
  scriptureText: PropTypes.string,
  hint: PropTypes.string,
};
