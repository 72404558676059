import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCookie } from './../utils/cookies';
import CardNavigation from './cardComponenets/CardNavigation';
import CardBody from './cardComponenets/CardBody';
import { getCard } from '../controllers/cardsCntl';

const renderCard = (card) => {
  const id = card.data.flashcard[0].id;
  const scripture = card.data.flashcard[0].scripture;
  const scriptureText = card.data.flashcard[0].scripture_text;
  const hint = card.data.flashcard[0].hint;
  return (
    <CardBody
      cardId={id}
      scripture={scripture}
      scriptureText={scriptureText}
      hint={hint}
    />
  );
};

export default function CardMain() {
  const [card, setCard] = useState(0);
  const [cardCnt, setCardCnt] = useState(getCookie('card-count'));
  const { id } = useParams();
  const [cardId, setCardId] = useState(id || 0);
  let displayCard = '';

  const getCardFromNavClick = (fcId) => {
    setCardId(fcId);
  };

  useEffect(() => {
    async function getCardEffect() {
      setCardCnt(getCookie('card-count'));
      const result = await getCard(cardId);
      console.log('RESULT', result);
      setCard(result);
    }
    getCardEffect();
  }, [cardId]);

  if (Object.keys(card).length > 0 && cardId !== 0) {
    displayCard = renderCard(card);
  }

  return (
    <div>
      <CardNavigation
        cardId={parseInt(cardId)}
        cardCount={cardCnt}
        getCardFromNavClick={getCardFromNavClick}
      />
      <div>{displayCard}</div>
    </div>
  );
}
