import React from 'react';
import { Link } from 'react-router-dom';
// import { setCookie } from './../utils/cookies';

export default function HomePage() {
  // const handleStartGame = () => {
  //   window.location.href = '/card/1';
  // };

  // const handleTimerOn = () => {};

  // const handleSetTimer = () => {};

  return (
    <div className='home-page columns is-mobile'>
      <div className='column'>
        <Link className='button box-shadow' to='/card/1'>
          Start Game
        </Link>
      </div>
      <div className='column'></div>
    </div>
  );
}
