export function setCookie(cname, cvalue, exdays = false) {
  if (exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname +
      '=' +
      JSON.stringify(cvalue) +
      ';' +
      expires +
      ';path=/; samesite=strict';
  } else {
    document.cookie =
      cname + '=' + JSON.stringify(cvalue) + ';path=/; samesite=strict';
  }
}

export function removeCookie(cname, cvalue, exdays = -1000) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname +
    '=' +
    JSON.stringify(cvalue) +
    ';' +
    expires +
    ';path=/; samesite=strict';
}

export function getCookie(name) {
  let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result && (result = JSON.parse(result[1]));
  if (!result) return false;
  return result;
}

export function checkCookie(cname) {
  if (getCookie(cname)) {
    return true;
  }
  return false;
}
