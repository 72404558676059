import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Link } from 'react-router-dom';
import { checkCookie } from '../../utils/cookies';

export default function Header() {
  const handleMenuIconClick = () => {
    console.log('CLICKED');
  };

  let out = 0;
  let loginLink = <Link to={`/Login/${out}`}>Login</Link>;
  let addLink = '';
  console.log('LOGIN', checkCookie('loginData'));
  if (checkCookie('loginData')) {
    out = 1;
    loginLink = <Link to={`/Login/${out}`}>Log Out</Link>;
    addLink = (
      <MenuItem>
        <Link to={'/cardform/0'}>Add Flashcard</Link>
      </MenuItem>
    );
  }

  const Hamburger = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        color='#ccc'
        style={{ fontSize: '16px' }}
        onClick={handleMenuIconClick}
        title='Navigation'
      />
    );
  };

  return (
    <div className='main-header'>
      <div className='columns is-mobile'>
        <div
          className='column is-two-thirds has-background-grey-light'
          style={{ paddingTop: '15px' }}
        >
          <h2 className='header-title'>Bible Flashcards</h2>
        </div>
        <div
          className='column is-one-third has-background-grey-light'
          style={{ textAlign: 'right', marginTop: '10px' }}
        >
          <Menu
            menuButton={
              <MenuButton>
                <Hamburger />
              </MenuButton>
            }
          >
            <MenuItem>
              <Link to={'/'}>Home - Game Setup</Link>
            </MenuItem>
            {addLink}
            <MenuItem>
              <Link to={'/listcards'}>List Flashcards</Link>
            </MenuItem>
            <MenuItem>{loginLink}</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
