import axios from 'axios';
import { Buffer } from 'buffer';

export const signIn = async () => {
  const usernamePasswordBuffer = Buffer.from(
    process.env.REACT_APP_API_USER + ':' + process.env.REACT_APP_API_PASSWORD
  );

  const base64data = usernamePasswordBuffer.toString('base64');

  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/auth',
      {
        headers: {
          Authorization: `Basic ${base64data}`,
        },
      },
      { data: {}, query: {} }
    );

    return response.data.jwt;
  } catch (error) {
    return error;
  }
};

export const login = async (user, passwd) => {
  try {
    const token = await signIn();

    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/login',
      {
        email: user,
        password: passwd,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log('LOGIN RESPONSE', response);
    return response.data;
  } catch (error) {
    return error;
  }
};
