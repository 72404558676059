/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global-styles.scss';
import Main from './components/Main';

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

root.render(<Main />);
