import axios from 'axios';
import { signIn } from './basicAuth';

export const cardsList = async () => {
  const bearerToken = await signIn();

  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/allcards', {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    });

    // console.log('RESPONSE', response);
    return response.data;
  } catch (error) {
    return [error];
  }
};

export const cardCount = async () => {
  const bearerToken = await signIn();

  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/count', {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data.data.cardCount[0].cardCount;
  } catch (error) {
    return [error];
  }
};

export const getCard = async (id) => {
  const bearerToken = await signIn();

  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + `/card/${id}`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    return [error];
  }
};

export const insertCard = async (data) => {
  const bearerToken = await signIn();

  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/newcard`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    return [error];
  }
};

export const updateCard = async (data) => {
  const bearerToken = await signIn();

  try {
    const response = await axios.put(
      process.env.REACT_APP_API_URL + `/update`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    return [error];
  }
};
