import React, { useState, useEffect, Fragment } from 'react';
import { cardsList } from '../controllers/cardsCntl';
import { Link } from 'react-router-dom';
import { checkCookie } from '../utils/cookies';
import PropTypes from 'prop-types';

const partition = (x, n) => {
  const p = x.length % n,
    q = Math.ceil(x.length / n),
    r = Math.floor(x.length / n);
  return [...Array(n)].reduce(
    (a, _, i) => (a[0].push(x.slice(a[1], (a[1] += i < p ? q : r))), a),
    [[], 0]
  )[0];
};

const Edit = (props) => {
  if (!checkCookie('loginData')) return '';
  return (
    <Link
      to={`cardform/${props.id}`}
      title={`edit ${props.scripture}`}
      style={{ paddingLeft: '10px' }}
    >
      - edit
    </Link>
  );
};

const ColumnItem = (props) => {
  return (
    <Fragment>
      <p style={{ overflow: 'visible', whiteSpace: 'nowrap' }}>
        <Link to={`/card/${props.id}`} title={`open ${props.scripture}`}>
          {props.scripture}
        </Link>
        <Edit id={parseInt(props.id)} scripture={props.scripture} />
      </p>
    </Fragment>
  );
};

const Column = (props) => {
  console.log(props.column);
  return props.column.map((card) => {
    return (
      <Fragment key={card.id}>
        <ColumnItem id={card.id} scripture={card.scripture} />
      </Fragment>
    );
  });
};

const renderList = (list) => {
  const listCols = partition(list, 3);
  // console.log(listCols);
  const listColumns = listCols.map((column, index) => {
    // console.log(column);
    return (
      <div key={index} className='column is-4'>
        <Column column={column} />
      </div>
    );
  });

  return <div className='columns is-desktop'>{listColumns}</div>;
};

export default function ListCards() {
  const [list, setList] = useState({});
  let displayList = '';

  useEffect(() => {
    async function getCards() {
      const cardList = await cardsList();
      const result = cardList.data.flashcards.map((card) => card);
      // console.log('RESULT', result);
      setList(result);
    }
    getCards();
  }, []);

  // console.log(list.length);
  if (list.length > 0) {
    displayList = renderList(list);
  }

  return (
    <div>
      <div className='cardsList columns is-mobile' style={{ marginTop: '10px' }}>
        <div className='column has-background-light is-full'>Flashcards List</div>
      </div>
      {displayList}
    </div>
  );
}

Edit.propTypes = {
  id: PropTypes.number,
  scripture: PropTypes.string,
};

ColumnItem.propTypes = {
  id: PropTypes.number,
  scripture: PropTypes.string,
};
