import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setCookie } from './../utils/cookies';
import { getCard, insertCard, updateCard } from '../controllers/cardsCntl';

const cleanText = (text) => {
  const regex = /\+|\*/gm;
  const cleanText = text.replace(regex, '');
  return cleanText;
};

export default function CardForm() {
  const { id } = useParams();
  const [cardId, setCardId] = useState(id);
  console.log('CARD ID', useParams());
  const [scripture, setScripture] = useState('');
  const [scriptureText, setScriptureText] = useState('');
  const [hint, setHint] = useState('');
  const [action, setAction] = useState('insert');

  const handleSubmit = async () => {
    let response;
    const cleanScripture = cleanText(scriptureText);
    const sendData = {
      id: id,
      scripture: scripture,
      scripture_text: cleanScripture,
      hint: hint,
    };

    if (action === 'insert') {
      response = await insertCard(sendData);
      setCookie('card-count', response.data.flashcard_id);
    } else {
      response = await updateCard(sendData);
    }
    console.log('RESPONSE', JSON.stringify(response));
    window.location.href = '/listcards';
  };

  const handleScriptureChange = (event) => {
    setScripture(event.target.value);
  };

  const handleScriptureTextChange = (event) => {
    setScriptureText(event.target.value);
  };

  const handleHintChange = (event) => {
    setHint(event.target.value);
  };

  useEffect(() => {
    setCardId(id);
  }, [id]);

  useEffect(() => {
    async function getCardEffect() {
      setScripture('');
      setScriptureText('');
      setHint('');
      setAction('insert');
      if (cardId > 0) {
        const result = await getCard(cardId);
        setScripture(result.data.flashcard[0].scripture);
        setScriptureText(result.data.flashcard[0].scripture_text);
        setHint(result.data.flashcard[0].hint);
        setAction('update');
      }
    }
    getCardEffect();
  }, [cardId]);

  return (
    <div style={{ marginTop: '20px' }}>
      <div className='field'>
        <label className='label'>Scripture:</label>
        <div className='control'>
          <input
            className='input'
            type='text'
            value={scripture}
            placeholder='Full name of bible book. E.g. Matthew 24:14'
            onChange={handleScriptureChange}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Scripture Text:</label>
        <div className='control'>
          <textarea
            className='textarea'
            type='text'
            rows='3'
            placeholder='Scripture Text'
            value={scriptureText}
            onChange={handleScriptureTextChange}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Hint:</label>
        <div className='control'>
          <input
            className='input'
            type='text'
            placeholder='Remember this is open to the public'
            value={hint}
            onChange={handleHintChange}
          />
        </div>
      </div>
      <div className='field is-grouped'>
        <div className='control'>
          <button className='button is-link' onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <div className='control'>
          <button className='button is-link is-light'>Cancel</button>
        </div>
      </div>
    </div>
  );
}
