import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { login } from './../controllers/basicAuth';
import { useParams } from 'react-router-dom';
import { setCookie, removeCookie, getCookie } from './../utils/cookies';

export default function Login() {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  let { out } = useParams();
  console.log('OUT', out);

  if (out) {
    const cookieData = getCookie('loginData');
    removeCookie('loginData', cookieData);
  }

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  function formIsValid() {
    const _errors = {};
    if (!formData.email) _errors.email = 'Email is Required';
    if (!formData.password) _errors.password = 'Password is Required';

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    const user = formData.email;
    const passwd = formData.password;
    const loginData = await login(user, passwd);
    console.log(loginData);
    if (!loginData) {
      const _errors = {};
      _errors.password = 'Login Failed Please Try Again';
      setErrors(_errors);
    }
    setCookie('loginData', loginData.data[0], 90);
    window.location.href = '/';
  };

  return (
    <section className='login'>
      <h1 className='is-size-4'>Login</h1>
      <form onSubmit={handleLoginSubmit}>
        <div className='field'>
          <p className='control has-icons-left has-icons-right'>
            <input
              className='input'
              type='email'
              id='email'
              name='email'
              value={formData.email}
              placeholder='Email'
              onChange={handleChange}
            />
            <span className='icon is-small is-left'>
              <FontAwesomeIcon
                icon={faEnvelope}
                color='#bababa'
                style={{ fontSize: '16px' }}
                title='Navigation'
              />
            </span>
          </p>
          {errors.email && <div className='alert alert-danger'>{errors.email}</div>}
        </div>
        <div className='field'>
          <p className='control has-icons-left'>
            <input
              className='input'
              type='password'
              id='password'
              name='password'
              value={formData.password}
              placeholder='Password'
              onChange={handleChange}
            />
            <span className='icon is-small is-left'>
              <FontAwesomeIcon
                icon={faLock}
                color='#bababa'
                style={{ fontSize: '16px' }}
                title='Navigation'
              />
            </span>
          </p>
          {errors.password && (
            <div className='alert alert-danger'>{errors.password}</div>
          )}
        </div>
        <div className='button-box has-text-centered'>
          <button className='button is-info' type='submit'>
            Log In
          </button>
        </div>
      </form>
    </section>
  );
}

Login.propTypes = {
  history: PropTypes.object,
  push: PropTypes.func,
};
